

<template>
  <v-container class="grey lighten-5">
    <v-row>
      <v-col cols="8" class="pa-0">
        <div class="profile__title">
          <!-- === MAIN HEADER ===  -->
          <h1 class="font-weight-light ml-2">{{$t('profile.title')}}</h1>
          <div class="profile__title__breadcrumbs">
            <HolidayForm />
            <span @click="changePassword">Zmiana hasła</span>
            <ChangePassword />
          </div>
        </div>
      </v-col>

      <v-col cols="4" class="pa-0 d-flex flex-row-reverse">
        <v-menu transition="slide-x-transition" bottom right>
          <template v-slot:activator="{ on }">
            <v-btn width="135px" text v-on="on">
              <v-avatar size="30">
                <img :src="getUserLanguage.avatar" alt="avatar" />
              </v-avatar>
              <span class="ma-2 font-weight-light">{{getUserLanguage.name}}</span>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item) in getLanguages"
              :key="item.language"
              @click="tryChangeLanguage(item.language)"
            >
              <v-avatar class="ma-2" size="30">
                <img :src="item.avatar" alt="avatar" />
              </v-avatar>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>

      <v-col cols="12" md="12" class="pa-0 d-flex flex-wrap">
        <v-col cols="12" lg="3" md="6" sm="6">
          <v-card class="pa-5" min-height="775" min-width="250">
            <v-card-title class="px-0">Twoje dane</v-card-title>
            <div class="profile__date">
              <div class="profile__date__content">
                <v-avatar size="80" color="primary">
                  <img v-if="getUser.picture" :src="showProfilePicture" alt="avatar" />
                  <span v-else class="white--text headline mx-auto">{{avatar}}</span>
                </v-avatar>
                <v-btn
                  class="profile__date__img__edit"
                  @click.stop="dialog = true"
                  width="25"
                  height="25"
                  icon
                >
                  <v-icon size="16" color="white">mdi-pencil</v-icon>
                </v-btn>
              </div>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    class="title text-wrap center"
                  >{{getUser.name}} {{getUser.lastname}}</v-list-item-title>
                  <v-list-item-subtitle class="center">{{getUser.job}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>

            <v-text-field disabled label="Adres email:" :value="setEmail(getUser.mail)"></v-text-field>
            <v-text-field disabled label="Telefon komórkowy:" :value="setPhone(getUser.phone)"></v-text-field>
            <v-text-field disabled label="Login do platformy:" v-model="getUser.login"></v-text-field>

            <v-list dense>
              <v-subheader class="pa-0 profile__date__department">Departament</v-subheader>

              <v-list-item-group class="profile__date__department__height">
                <v-list-item
                  class="pa-0"
                  color="grey"
                  v-for="item in getUserDepartments"
                  :key="item.fullName"
                >
                  <v-list-item-content class="pa-0">
                    <v-list-item-title class="profile__date__department" v-html="item.fullName"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <v-dialog v-model="dialog" max-width="600">
              <v-card class="p-relative">
                <v-btn
                  icon
                  color="primary"
                  class="p-absolute top-right"
                  style="position: absolute; top: 0px; right: 0px"
                  @click="dialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-card-title>
                  <Separator :text="'Zmiana zdjęcia'" />
                </v-card-title>

                <div class="pa-4 p-relative">
                  <Cropper
                    class="cropper"
                    :src="image"
                    :stencilComponent="$options.components.CircleStencil"
                    ref="cropper"
                  />

                  <div class="cropper__content">
                    <v-icon size="60" class="ma-5" color="#445D75">mdi-arrow-down-bold-circle</v-icon>
                    <p>Kliknij lub przeciągnij zdjęcie...</p>
                  </div>

                  <div class="button-wrapper">
                    <form enctype="multipart/form-data">
                      <span class="button" @click="$refs.file.click()">
                        <input
                          type="file"
                          ref="file"
                          @change="uploadImage($event)"
                          accept="image/*"
                          name="image-event"
                          id="image-event"
                        />
                      </span>
                    </form>
                  </div>
                </div>

                <v-card-actions class="pb-4 pt-0">
                  <v-alert v-if="showProccessing" dense type="info" class="mb-0">Przetwarzanie...</v-alert>
                  <div class="flex-grow-1"></div>
                  <v-btn class="buttons v-btn--contained" text @click="$refs.file.click()">Wybierz</v-btn>
                  <v-btn class="buttons buttons--add" text @click="sendPictures">Zapisz</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
        <v-col cols="9" v-if="false">
          <v-card class="pa-5 profile__details">
            <v-card-title class="px-0">Nieobecności</v-card-title>

            <div class="row">
              <v-col cols="6">
                <span>Nieobecności za bieżący rok</span>

                <v-divider></v-divider>
                <div class="profile__details__top">
                  <v-text-field value="0" disabled label="Dni chorobowych:"></v-text-field>
                  <v-text-field value="0" disabled label="Dni urlopu:"></v-text-field>
                  <v-text-field value="0" disabled label="Dni urlopu na żądanie:"></v-text-field>
                </div>
              </v-col>
              <v-col cols="6">
                <span>Nieobecności za poprzedni rok</span>
                <v-divider></v-divider>
                <div class="profile__details__top">
                  <v-text-field value="0" disabled label="Dni chorobowych:"></v-text-field>
                  <v-text-field value="0" disabled label="Dni urlopu:"></v-text-field>
                  <v-text-field value="0" disabled label="Dni urlopu na żądanie:"></v-text-field>
                </div>
              </v-col>
            </div>
            <div class="row">
              <v-col cols="12">
                <p>Wnioski urlopowe</p>

                <v-data-table
                  :headers="headers"
                  fixed-header
                  :items="desserts"
                  class="elevation-1 profile__tabele"
                  height="250px"
                ></v-data-table>
              </v-col>
            </div>
          </v-card>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Vue from "vue";
import { Cropper, CircleStencil } from "vue-advanced-cropper";
import Separator from "@/components/Global/Separator.vue";
import HolidayForm from "@/components/Personal/Profile/HolidayRequestForm.vue";
import ChangePassword from "@/components/Personal/Profile/ChangePassword.vue";

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  components: {
    Cropper,
    Separator,
    CircleStencil,
    HolidayForm,
    ChangePassword
  },
  data() {
    return {
      dialog: false,
      coordinates: {
        width: 32,
        height: 42,
        left: 102,
        top: 74
      },
      file_before: "",
      image: null,
      showProccessing: false,
      headers: [
        {
          text: "Numer",
          align: "left",
          value: "name",
          width: "150",
          class: "central__table__header__profil"
        },
        {
          text: "Stan",
          value: "status",
          width: "120",
          class: "central__table__header__profil"
        },
        {
          text: "Przełożony",
          value: "supervisor",
          class: "central__table__header__profil"
        },
        {
          text: "Początek urlopu:",
          value: "start",
          class: "central__table__header__profil"
        },
        {
          text: "Koniec urlopu włącznie:",
          value: "end",
          class: "central__table__header__profil"
        },
        {
          text: "Pracownik zastępujący",
          value: "proxy",
          class: "central__table__header__profil"
        }
      ],
      desserts: []
    };
  },
  methods: {
    ...mapMutations([
      "changeHoliday",
      "changePassword",
      "setUserLanguage",
      "setSecondModal",
      "setSnackbars"
    ]),
    ...mapActions([
      "fetchItems",
      "tryChangeLanguage",
      "userDepartmentsProfile",
      // "userDeatils",
      "openSecondModal"
    ]),
    // acceptModal() {
    //     this.openSecondModal({
    //         headLine: "global.create",
    //         width: 500,
    //         open: true,
    //         type: "edit",
    //         route: "service",
    //         tab: "new_service"
    //     });
    // },

    async sendPictures() {
      let tmsp = new Date().getTime();
      const formData = new FormData();
      formData.append("tmsp", tmsp);
      formData.append("before", this.file_before);
      this.showProccessing = true;
      const { canvas } = this.$refs.cropper.getResult();
      await Vue.axios({
        method: "POST",
        url: "/users/picture",
        data: formData
      })
        .then(async () => {
          await Vue.axios({
            method: "POST",
            url: "/users/picture",
            data: {
              after: canvas.toDataURL(),
              tmsp
            }
          })
            .then(response => {
              this.$store.commit("setUserPicture", response.data.item);
              this.showProccessing = false;
              this.dialog = false;

              this.$store.commit("setSnackbars", {
                type: "success",
                text: "Zdjęcie zostało zmienione"
              });
            })
            .catch(error => {
              this.showProccessing = false;

              this.$store.commit("setSnackbars", {
                type: "error",
                text: "Coś poszło nie tak"
              });
            });
        })
        .catch(() => {
          this.showProccessing = false;

          this.$store.commit("setSnackbars", {
            type: "error",
            text: "Coś poszło nie tak"
          });
        });
    },
    uploadImage(event) {
      this.file_before = this.$refs.file.files[0];
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = e => {
          this.image = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    setEmail(mail) {
      return mail ? mail : "Brak";
    },
    setPhone(phone) {
      return phone ? phone : "Brak";
    }
  },
  beforeMount() {
    // this.userDeatils({ id: this.getUser.id });
    this.userDepartmentsProfile({ id: this.getUser.id });
  },
  computed: {
    ...mapGetters([
      "getUser",
      "getLanguages",
      "getUserLanguage",
      "getGlobalPaths",
      "getUserDepartments",
      "getSnackbars"
    ]),
    avatar() {
      return `${this.getUser.name[0]}${this.getUser.lastname[0]}`;
    },
    showProfilePicture() {
      return this.getGlobalPaths.profilePic + this.getUser.picture;
    }
  },
  mounted() {
    this.setUserLanguage();
  }
};
</script>



