<template>
    <v-dialog
        max-width="600px"
        v-model="getHoliday"
        @click:outside="closeHoliday"
    >
        <v-card>
            <div class="AE__header">
                <h1 class="font-weight-light AE__header__title py-3 pl-5">
                    Wniosek urlopowy
                </h1>
                <v-btn
                    class="AE__header__close"
                    color="white"
                    small
                    icon
                    @click="closeHoliday"
                >
                    <v-icon medium>mdi-close</v-icon>
                </v-btn>
            </div>

            <v-container style="position: relative">
                <v-row>
                    <v-col cols="12" sm="6" md="6">
                        <v-menu
                            transition="scale-transition"
                            offset-y
                            :close-on-content-click="false"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="startHoliday"
                                    label="Początek urlopu*"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                first-day-of-week="1"
                                ref="picker"
                                local="pl"
                                v-model="startHoliday"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-menu
                            transition="scale-transition"
                            offset-y
                            :close-on-content-click="false"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="endHoliday"
                                    label="Koniec urlopu włącznie*"
                                    :disabled="endActive"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                first-day-of-week="1"
                                :min="minEnd"
                                ref="picker"
                                local="pl"
                                v-model="endHoliday"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-divider></v-divider>
                    <v-col cols="12" sm="6">
                        <v-autocomplete
                            required
                            label="Rodzaj urlopu*"
                            :items="holidayType"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-autocomplete
                            :items="holidayYear"
                            label="Urlop za rok*"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12">
                        <v-autocomplete
                            height="47"
                            item-value="_id"
                            :items="getDropdown.items.users"
                            label="Pracownik zastępujący:*"
                        >
                            <template v-slot:selection="data">
                                <v-chip
                                    v-bind="data.attrs"
                                    :input-value="data.selected"
                                    @click="data.select"
                                    pill
                                >
                                    <v-avatar
                                        left
                                        color="secondary"
                                        class="mr-2"
                                    >
                                        <span
                                            class="white--text caption"
                                            v-if="!data.item.picture"
                                            >{{
                                                avatarNick(
                                                    data.item.name,
                                                    data.item.lastname
                                                )
                                            }}</span
                                        >
                                        <img
                                            :src="data.item.picture"
                                            v-else
                                            style="object-fit: cover"
                                        />
                                    </v-avatar>
                                    {{ data.item.name }}
                                    {{ data.item.lastname }}
                                </v-chip>
                            </template>

                            <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                    <v-list-item-content
                                        v-text="data.item"
                                    ></v-list-item-content>
                                </template>

                                <template v-else>
                                    <v-list-item-avatar>
                                        <v-avatar
                                            left
                                            color="secondary"
                                            class="mr-2"
                                        >
                                            <span
                                                class="white--text caption"
                                                v-if="!data.item.picture"
                                                >{{
                                                    avatarNick(
                                                        data.item.name,
                                                        data.item.lastname
                                                    )
                                                }}</span
                                            >
                                            <img
                                                :src="data.item.picture"
                                                v-else
                                                style="object-fit: cover"
                                            />
                                        </v-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >{{ data.item.name }}
                                            {{
                                                data.item.lastname
                                            }}</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </template>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col>
                        <v-textarea
                            height="80"
                            no-resize
                            label="Uzasadnienie:"
                            messages="Wypełniamy gdy urlop jest bezpłatny lub inny"
                        ></v-textarea>
                    </v-col>
                </v-row>
                <small
                    >*Pola z gwiazdką są wymagane. Możesz złożyć tylko jeden
                    wniosek, po decyzji, złóż kolejny.</small
                >
            </v-container>

            <v-footer style="padding: 6px 16px; height: 80px">
                <v-spacer></v-spacer>
                <div>
                    <v-btn @click="closeHoliday" class="mr-3 buttons"
                        >Anuluj</v-btn
                    >
                    <v-btn
                        dark
                        style="width: 120px !important;"
                        class="buttons buttons--add"
                        >Złóż wniosek</v-btn
                    >
                </div>
            </v-footer>
        </v-card>
    </v-dialog>
</template>

<script>
import Header from '@/components/Global/Modal/ModalHeader.vue';

import { mapGetters, mapMutations, mapActions } from 'vuex';
export default {
    data: () => ({
        startHoliday: null,
        endHoliday: null,
        menu: false,
        minEnd: null,
        endActive: true,
        holidayType: [
            'Wypoczynkowy',
            'Okolicznościowy',
            'Bezpłatny',
            'Opieka na dziecko art.188 KP',
            'Na żadanie',
            'Inne (wymaga opisu w polu poniżej)',
        ],
        holidayYear: [
            new Date().getFullYear() + 1,
            new Date().getFullYear(),
            new Date().getFullYear() - 1,
        ],
    }),

    computed: {
        ...mapGetters(['getHoliday', 'getDropdown']),
    },
    methods: {
        ...mapMutations(['closeHoliday']),
        avatarNick(name, lastname) {
            let nick = name[0] + lastname[0];
            return nick.toUpperCase();
        },
    },
    components: {
        Header,
    },
    watch: {
        startHoliday(late) {
            if (late) {
                this.endActive = false;
                this.minEnd = late;
            }
        },
    },
};
</script>
